import AdjustmentButtonAccordion from 'component/ContractAdjustmentPage/AdjustmentButtonAccordion'
import AdjustmentTable from 'component/ContractAdjustmentPage/AdjustmentTable'
import BoxFullWidth from 'component/material/BoxFullWidth'
import React from 'react'

const ContractAdjustmentPage = () => {
  return (
    <BoxFullWidth>
      <AdjustmentButtonAccordion />
      <AdjustmentTable />
    </BoxFullWidth>
  )
}

export default ContractAdjustmentPage
