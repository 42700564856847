import {
  GET_ADJUSTMENTS_FAIL,
  GET_ADJUSTMENTS_REQUEST,
  GET_ADJUSTMENTS_SUCCESS,
  GET_ATTACHMENTS_FAIL,
  GET_ATTACHMENTS_REQUEST,
  GET_ATTACHMENTS_SUCCESS,
  GET_CARS_FAIL,
  GET_CARS_REQUEST,
  GET_CARS_SUCCESS,
  GET_CFS_FAIL,
  GET_CFS_LIST_FAIL,
  GET_CFS_LIST_REQUEST,
  GET_CFS_LIST_SUCCESS,
  GET_CFS_REQUEST,
  GET_CFS_SUCCESS,
  GET_FLEET_TERMS_FAIL,
  GET_FLEET_TERMS_REQUEST,
  GET_FLEET_TERMS_SUCCESS,
  GET_MY_FLEETS_FAIL,
  GET_MY_FLEETS_REQUEST,
  GET_MY_FLEETS_SUCCESS,
  GET_MY_SUPPLIERS_FAIL,
  GET_MY_SUPPLIERS_REQUEST,
  GET_MY_SUPPLIERS_SUCCESS,
  GET_PREDEFINED_TERMS_FAIL,
  GET_PREDEFINED_TERMS_REQUEST,
  GET_PREDEFINED_TERMS_SUCCESS,
  GET_SUP_AVAILA_FAIL,
  GET_SUP_AVAILA_REQUEST,
  GET_SUP_AVAILA_SUCCESS,
  GET_UNSIGNED_CONTRACT_FAIL,
  GET_UNSIGNED_CONTRACT_REQUEST,
  GET_UNSIGNED_CONTRACT_SUCCESS,
} from 'redux/actionType'

const initState = {
  cfsList: [],
  cfsListLoading: false,
  cfsListMeta: {},
  cfs: {},
  cfsLoading: false,
  car: {},
  carLoading: false,
  attachmentTypes: [],
  attachmentTypesMeta: {},
  attachmentTypesLoading: false,
  mySupplier: {},
  mySupplierLoading: false,
  myFleets: [],
  myFleetsLoading: false,
  myFleetsMeta: {},
  predefinedTerms: [],
  predefinedTermsMeta: {},
  predefinedTermsLoading: false,
  supplierUnava: [],
  supplierUnavaLoading: false,
  supplierUnavaMeta: {},
  fleetTerms: [],
  fleetTermsMeta: {},
  fleetTermsLoading: false,
  contractAdjustments: [],
  contractAdjustmentsMeta: {},
  contractAdjustmentsLoading: false,
  numberOfUnsignedContract: 0,
}

const cfsReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_CFS_LIST_REQUEST: {
      return {
        ...state,
        cfsListLoading: true,
      }
    }
    case GET_CFS_LIST_SUCCESS: {
      return {
        ...state,
        cfsListLoading: false,
        cfsList: action.data?.objects,
        cfsListMeta: action.data?.meta,
      }
    }
    case GET_CFS_LIST_FAIL: {
      return {
        ...state,
        cfsListLoading: false,
      }
    }
    case GET_CFS_REQUEST: {
      return {
        ...state,
        cfsLoading: true,
      }
    }
    case GET_CFS_SUCCESS: {
      return {
        ...state,
        cfs: action.data,
        cfsLoading: false,
      }
    }
    case GET_CFS_FAIL: {
      return {
        ...state,
        cfsLoading: false,
      }
    }
    case GET_ATTACHMENTS_REQUEST: {
      return {
        ...state,
        attachmentTypesLoading: true,
      }
    }
    case GET_ATTACHMENTS_SUCCESS: {
      return {
        ...state,
        attachmentTypesLoading: false,
        attachmentTypes: action.data.objects,
        attachmentTypesMeta: action.data.meta,
      }
    }
    case GET_ATTACHMENTS_FAIL: {
      return {
        ...state,
        attachmentTypesLoading: false,
      }
    }
    case GET_MY_SUPPLIERS_REQUEST: {
      return {
        ...state,
        mySupplierLoading: true,
      }
    }
    case GET_MY_SUPPLIERS_SUCCESS: {
      return {
        ...state,
        mySupplierLoading: false,
        mySupplier: action.data,
      }
    }
    case GET_MY_SUPPLIERS_FAIL: {
      return {
        ...state,
        mySupplierLoading: false,
      }
    }
    case GET_MY_FLEETS_REQUEST: {
      return {
        ...state,
        myFleetsLoading: true,
      }
    }
    case GET_MY_FLEETS_SUCCESS: {
      return {
        ...state,
        myFleetsLoading: false,
        myFleets: action.data.objects,
        myFleetsMeta: action.data.meta,
      }
    }
    case GET_MY_FLEETS_FAIL: {
      return {
        ...state,
        myFleetsLoading: false,
      }
    }
    case GET_CARS_REQUEST: {
      return {
        ...state,
        carLoading: true,
      }
    }
    case GET_CARS_SUCCESS: {
      return {
        ...state,
        carLoading: false,
        car: action.data,
      }
    }
    case GET_CARS_FAIL: {
      return {
        ...state,
        carLoading: false,
      }
    }
    case GET_PREDEFINED_TERMS_REQUEST: {
      return {
        ...state,
        predefinedTermsLoading: true,
      }
    }
    case GET_PREDEFINED_TERMS_SUCCESS: {
      return {
        ...state,
        predefinedTermsLoading: false,
        predefinedTermsMeta: action.data.meta,
        predefinedTerms: action.data.objects,
      }
    }
    case GET_PREDEFINED_TERMS_FAIL: {
      return {
        ...state,
        predefinedTermsLoading: false,
      }
    }
    case GET_SUP_AVAILA_REQUEST: {
      return {
        ...state,
        supplierUnavaLoading: true,
      }
    }
    case GET_SUP_AVAILA_SUCCESS: {
      return {
        ...state,
        supplierUnavaLoading: false,
        supplierUnava: action.data.objects,
        supplierUnavaMeta: action.data.meta,
      }
    }
    case GET_SUP_AVAILA_FAIL: {
      return {
        ...state,
        supplierUnavaLoading: false,
      }
    }
    case GET_FLEET_TERMS_REQUEST: {
      return {
        ...state,
        fleetTermsLoading: true,
      }
    }
    case GET_FLEET_TERMS_SUCCESS: {
      return {
        ...state,
        fleetTerms: action.data.objects,
        fleetTermsMeta: action.data.meta,
        fleetTermsLoading: false,
      }
    }
    case GET_FLEET_TERMS_FAIL: {
      return {
        ...state,
        fleetTermsLoading: false,
      }
    }
    case GET_ADJUSTMENTS_REQUEST: {
      return {
        ...state,
        contractAdjustmentsLoading: true,
      }
    }
    case GET_ADJUSTMENTS_SUCCESS: {
      return {
        ...state,
        contractAdjustments: action.data.objects,
        contractAdjustmentsMeta: action.data.meta,
        contractAdjustmentsLoading: false,
      }
    }
    case GET_ADJUSTMENTS_FAIL: {
      return {
        ...state,
        contractAdjustmentsLoading: false,
      }
    }
    case GET_UNSIGNED_CONTRACT_REQUEST: {
      return {
        ...state,
      }
    }
    case GET_UNSIGNED_CONTRACT_SUCCESS: {
      return {
        ...state,
        numberOfUnsignedContract: action.data?.meta?.total_count,
      }
    }
    case GET_UNSIGNED_CONTRACT_FAIL: {
      return {
        ...state,
        numberOfUnsignedContract: 0,
      }
    }
    default: {
      return state
    }
  }
}

export default cfsReducer
