import cx from 'classnames'
import componentStyle from 'component/componentStyle'
import PrimaryButton from 'component/material/PrimaryButton'
import download from 'downloadjs'
import {getContentDispositionFilename} from 'helper/functions'
import privateLayoutStyle from 'layout/PrivateLayout/privateLayoutStyle'
import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {downloadMyContract, downloadMyContractArchive} from 'redux/action/cfsAction'
import {Trans} from '@lingui/macro'
import {Accordion, AccordionSummary} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

const AdjustmentButtonAccordion = (props) => {
  const {classes, downloadMyContract, downloadMyContractArchive} = props

  const handleDownloadCurrentContract = () => {
    downloadMyContract()
      .then((res) => {
        const filename = getContentDispositionFilename(res.headers)
        const data = res.data
        download(data, filename, 'application/pdf')

        // create blob from array buffer in order to create url and then open it in new tab
        const blob = new Blob([data], {type: 'application/pdf'})
        const url = URL.createObjectURL(blob)
        window.open(url, '_blank')
      })
      .catch(() => {})
  }

  const handleDownloadContractArchive = () => {
    downloadMyContractArchive()
      .then((res) => {
        const filename = getContentDispositionFilename(res.headers)
        const data = res.data
        download(data, filename, 'application/zip')
      })
      .catch(() => {})
  }

  return (
    <Accordion
      className={cx(classes.tableFilters, classes.tabsCursorDefault)}
      elevation={0}
      expanded={true}
      defaultExpanded={true}
    >
      <AccordionSummary>
        <PrimaryButton
          size={'small'}
          fullWidth={false}
          textPadding={0}
          text={<Trans>Download current contract</Trans>}
          className={classes.marginRight}
          onClick={handleDownloadCurrentContract}
        />
        <PrimaryButton
          size={'small'}
          fullWidth={false}
          textPadding={0}
          text={<Trans>Download contract archive</Trans>}
          onClick={handleDownloadContractArchive}
        />
      </AccordionSummary>
    </Accordion>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({downloadMyContract, downloadMyContractArchive}, dispatch)
}

export default compose(
  withStyles((theme) => ({
    ...componentStyle(theme),
    ...privateLayoutStyle(theme),
  })),
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(AdjustmentButtonAccordion)
