import componentStyle from 'component/componentStyle'
import CheckBoxInput from 'component/field/CheckBoxInput'
import TextInput from 'component/field/TextInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {debounce} from 'helper/functions'
import {useEffect, useState} from 'react'
import {compose} from 'redux'
import {Trans} from '@lingui/macro'
import {Accordion, AccordionDetails, AccordionSummary} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

let timeoutId = null

const AdjustmentFilter = (props) => {
  const {
    classes,
    setTableFilter,
    handleChangePage,
    tableFilter,
    expandedFilters,
    setExpandedFilters,
  } = props

  const [isSigned, setIsSigned] = useState(tableFilter.is_signed)

  const onFilterChange = (value, name) => {
    setIsSigned(value)
    timeoutId = debounce(timeoutId, () => {
      setTableFilter((prevState) => ({...prevState, [name]: value}))
      handleChangePage(0)
    })
  }

  useEffect(() => {
    return () => {
      timeoutId && clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    if (tableFilter.is_signed !== isSigned) {
      setIsSigned(tableFilter.is_signed)
    }
  }, [tableFilter.is_signed])

  return (
    <Accordion
      className={classes.tableFilters}
      elevation={0}
      expanded={expandedFilters}
      onChange={(event, expanded) => setExpandedFilters(expanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <BoxFullWidth className={classes.formTitle}>
          <Trans>Filters</Trans>
        </BoxFullWidth>
      </AccordionSummary>
      <AccordionDetails>
        <GridContainer spacing={4} direction="row" justifyContent="flex-start" alignItems="center">
          <GridItem container xs={12} sm={6} md={4} lg={3}>
            <TextInput
              name="fleet_code"
              label={<Trans>Fleet code</Trans>}
              onChange={(e) => onFilterChange(e.target.value, 'fleet_code')}
              showHelperText={false}
            />
          </GridItem>
          <GridItem container xs={12} sm={6} md={4} lg={3}>
            <CheckBoxInput
              name="is_signed"
              label={<Trans>Show only unsigned</Trans>}
              onChange={(e) => onFilterChange(e.target.checked ? false : null, 'is_signed')}
              showHelperText={false}
              checked={isSigned === false}
            />
          </GridItem>
        </GridContainer>
      </AccordionDetails>
    </Accordion>
  )
}

export default compose(
  withStyles((theme) => ({
    ...componentStyle(theme),
  }))
)(AdjustmentFilter)
