import cx from 'classnames'
import FleetTermsDialog from 'component/FleetPage/FleetTermsDialog'
import componentStyle from 'component/componentStyle'
import detailPageStyle from 'component/detailPageStyle'
import BoxFullWidth from 'component/material/BoxFullWidth'
import CustomTooltip from 'component/material/CustomTooltip'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import Table from 'component/material/table/Table'
import {redirectTo} from 'helper/functions'
import {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {getMyFleets} from 'redux/action/cfsAction'
import {Trans} from '@lingui/macro'
import {useTheme} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import {WarningRounded} from '@material-ui/icons'
import DescriptionIcon from '@material-ui/icons/Description'
import useTablePage from 'hooks/useTablePage'
import useTableSort from 'hooks/useTableSort'

const FleetPage = (props) => {
  const {classes, rowsPerPage, getMyFleets, myFleets, myFleetsLoading, myFleetsMeta} = props

  const theme = useTheme()

  const [tableSort, handleTableSort] = useTableSort({orderBy: 'customer', orderDirection: 'ASC'})
  const [page, handleChangePage] = useTablePage()

  const [openFleetTermsDialog, setOpenFleetTermsDialog] = useState({visible: false, row: null})

  const handleOpenTermsDialog = (row) => () => {
    setOpenFleetTermsDialog({visible: true, row: row})
  }
  const handleCloseTermsDialog = () => {
    setOpenFleetTermsDialog({visible: false, row: null})
  }

  const columns = [
    {
      name: 'id',
      sortKey: 'id',
      align: 'right',
      width: '60px',
      label: <Trans>ID</Trans>,
    },
    {
      name: 'code',
      sortKey: 'code',
      label: <Trans>Code</Trans>,
    },
    {
      name: 'customer',
      sortKey: 'customer',
      label: <Trans>Name</Trans>,
    },
    {
      name: 'customer_cfs',
      sortKey: 'customer_cfs',
      label: <Trans>CFS</Trans>,
    },
    {
      name: 'customer_ic',
      sortKey: 'customer_ic',
      label: <Trans>IC</Trans>,
    },
    {
      name: 'customer_dic',
      sortKey: 'customer_dic',
      label: <Trans>DIC</Trans>,
    },
    // {
    //   name: 'do_not_show_on_map_for_this_supplier',
    //   sortKey: 'do_not_show_on_map_for_this_supplier',
    //   label: <Trans>Do not show on map</Trans>,
    //   render: (val) => (val ? <Trans>Yes</Trans> : <Trans>No</Trans>),
    // },
    {
      name: 'id',
      label: '',
      align: 'right',
      width: '80px',
      render: (val, row) => (
        <GridContainer
          wrap={'nowrap'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          spacing={2}
        >
          {row.has_unsigned_contract_adjustment && (
            <GridItem>
              <CustomTooltip
                title={
                  <Trans>
                    You have an unconfirmed contract adjustment to this fleet, please confirm the
                    adjustment. Clicking on this icon will take you to the list of unsigned
                    adjustment.
                  </Trans>
                }
              >
                <WarningRounded
                  className={cx(classes.cursorPointer, classes.warningIcon)}
                  width={'18px'}
                  height={'18px'}
                  fill={theme.palette.warning.main}
                  onClick={() => redirectTo('/contract-adjustments?is_signed=false')}
                />
              </CustomTooltip>
            </GridItem>
          )}
          <GridItem>
            <div
              onClick={handleOpenTermsDialog(row)}
              className={cx(classes.cursorPointer, classes.textCenter, classes.documentIcon)}
            >
              <DescriptionIcon />
            </div>
          </GridItem>
        </GridContainer>
      ),
    },
  ]

  const handleGetMyFleets = () => {
    getMyFleets(rowsPerPage, page * rowsPerPage, tableSort).catch(() => {})
  }

  useEffect(() => {
    handleGetMyFleets()
  }, [page, rowsPerPage, tableSort])

  return (
    <>
      <BoxFullWidth>
        <div className={classes.overFlowAuto}>
          <Table
            data={myFleets}
            columns={columns}
            loading={myFleetsLoading}
            meta={myFleetsMeta}
            onTableColumnSort={handleTableSort}
            tableSort={tableSort}
            page={page}
            onChangePage={handleChangePage}
          />
        </div>
      </BoxFullWidth>

      <FleetTermsDialog
        onClose={handleCloseTermsDialog}
        open={openFleetTermsDialog.visible}
        row={openFleetTermsDialog.row}
        handleGetMyFleets={handleGetMyFleets}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({getMyFleets}, dispatch)
}

export default compose(
  withStyles((theme) => ({
    ...componentStyle(theme),
    ...detailPageStyle(theme),
  })),
  connect((store) => {
    return {
      rowsPerPage: store.globalSettings.rowsPerPage,
      myFleets: store.cfs.myFleets,
      myFleetsLoading: store.cfs.myFleetsLoading,
      myFleetsMeta: store.cfs.myFleetsMeta,
    }
  }, mapDispatchToProps)
)(FleetPage)
