import cx from 'classnames'
import AdjustmentFilter from 'component/ContractAdjustmentPage/AdjustmentFilter'
import detailPageStyle from 'component/detailPageStyle'
import CustomTooltip from 'component/material/CustomTooltip'
import PrimaryButton from 'component/material/PrimaryButton'
import WarningDialog from 'component/material/WarningDialog'
import Table from 'component/material/table/Table'
import download from 'downloadjs'
import {DATE_FORMAT} from 'helper/constants'
import {getContentDispositionFilename} from 'helper/functions'
import browserHistory from 'helper/history'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {
  downloadMyContractAdjustments,
  downloadMyUnsignedContractAdjustments,
  getMyContractAdjustments,
  getUnsignedContractAdjustments,
  patchMyContractAdjustments,
} from 'redux/action/cfsAction'
import buttonStyle from 'style/components/buttonStyle'
import {Trans} from '@lingui/macro'
import {Link} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import useTablePage from 'hooks/useTablePage'
import useTableSort from 'hooks/useTableSort'

const AdjustmentTable = (props) => {
  const {
    classes,
    rowsPerPage,
    getMyContractAdjustments,
    contractAdjustments,
    contractAdjustmentsMeta,
    contractAdjustmentsLoading,
    downloadMyContractAdjustments,
    patchMyContractAdjustments,
    downloadMyUnsignedContractAdjustments,
    getUnsignedContractAdjustments,
  } = props

  let location = useLocation()

  const [tableSort, handleTableSort] = useTableSort({orderBy: 'created_at', orderDirection: 'DESC'})
  const [page, handleChangePage] = useTablePage()

  const [expandedFilters, setExpandedFilters] = useState(false)
  const [contractAdjustmentLoading, setContractAdjustmentLoading] = useState(false)

  const [tableFilter, setTableFilter] = useState({})
  const [confirmationDialog, setConfirmationDialog] = useState({visible: false, row: null})

  const handleDownloadAdjustment = (adjustmentId) => {
    downloadMyContractAdjustments(adjustmentId)
      .then((res) => {
        const filename = getContentDispositionFilename(res.headers)
        const data = res.data
        download(data, filename, 'application/pdf')

        // create blob from array buffer in order to create url and then open it in new tab
        const blob = new Blob([data], {type: 'application/pdf'})
        const url = URL.createObjectURL(blob)
        window.open(url, '_blank')
      })
      .catch(() => {})
  }

  const handleDownloadUnsignedAdjustment = (adjustmentId) => {
    downloadMyUnsignedContractAdjustments(adjustmentId)
      .then((res) => {
        const filename = getContentDispositionFilename(res.headers)
        const data = res.data
        download(data, filename, 'application/pdf')

        // create blob from array buffer in order to create url and then open it in new tab
        const blob = new Blob([data], {type: 'application/pdf'})
        const url = URL.createObjectURL(blob)
        window.open(url, '_blank')
      })
      .catch(() => {})
  }

  const columns = [
    {
      name: 'id',
      sortKey: 'id',
      align: 'right',
      width: '60px',
      label: <Trans>ID</Trans>,
    },
    {
      name: 'signed_file',
      label: <Trans>Filename</Trans>,
      render: (val, row) =>
        val?.filename ? (
          <Link
            className={classes.cursorPointer}
            onClick={() => row.id && handleDownloadAdjustment(row.id)}
          >
            {val.filename}
          </Link>
        ) : (
          <Link
            className={classes.cursorPointer}
            onClick={() => row.id && handleDownloadUnsignedAdjustment(row.id)}
          >
            {row.unsigned_file?.filename || <Trans>Download contract adjustment preview</Trans>}
          </Link>
        ),
    },
    {
      name: 'fleets',
      label: <Trans>Fleets</Trans>,
      render: (val) => (
        <CustomTooltip title={val.map((f) => f.customer).join(', ')}>
          <Typography className={cx(classes.fleetsInTable, classes.tableFontSize)}>
            {val.map((f) => f.customer).join(', ')}
          </Typography>
        </CustomTooltip>
      ),
    },
    {
      name: 'created_at',
      sortKey: 'created_at',
      label: <Trans>Created at</Trans>,
      render: (val) => val && moment(val).format(DATE_FORMAT),
    },
    {
      name: 'signed_at',
      sortKey: 'signed_at',
      label: <Trans>Signed at</Trans>,
      render: (val) =>
        val ? (
          moment(val).format(DATE_FORMAT)
        ) : (
          <Typography color={'error'} className={classes.tableFontSize}>
            <Trans>Not signed</Trans>
          </Typography>
        ),
    },
    {
      name: '',
      render: (val, row) =>
        !row.signed_at && ( // not signed yet
          <PrimaryButton
            size={'small'}
            textPadding={0}
            text={<Trans>Sign</Trans>}
            className={classes.successButton}
            onClick={() => setConfirmationDialog({visible: true, row: row})}
          />
        ),
    },
  ]

  const signContractAdjustment = () => {
    setContractAdjustmentLoading(true)
    patchMyContractAdjustments(confirmationDialog.row.id, {sign: true})
      .then(() => {
        setConfirmationDialog({visible: false, row: null})
        handleGetMyContractAdjustments()
        getUnsignedContractAdjustments().catch(() => {})
      })
      .catch(() => {})
      .finally(() => setContractAdjustmentLoading(false))
  }

  const handleGetMyContractAdjustments = () => {
    getMyContractAdjustments(rowsPerPage, page * rowsPerPage, tableSort, tableFilter).catch(
      () => {}
    )
  }

  useEffect(() => {
    // if there is query param which is not applied to filters yet, do so
    if (location.search?.includes('is_signed=false') && tableFilter?.is_signed !== false) {
      // remove query param, set filter and open filter accordion
      browserHistory.replace({pathname: location.pathname, search: ''})
      const splitSearch = location.search?.replaceAll('?', '').split('&')
      const isSomeSignedFalse = splitSearch.some((s) => s.includes('is_signed=false'))
      if (isSomeSignedFalse) {
        setTableFilter({is_signed: false})
        setExpandedFilters(true)
      }
    } else {
      // otherwise do get request with all filters etc.
      handleGetMyContractAdjustments()
    }
  }, [rowsPerPage, page * rowsPerPage, tableSort, tableFilter])

  return (
    <>
      <AdjustmentFilter
        tableFilter={tableFilter}
        setTableFilter={setTableFilter}
        handleChangePage={handleChangePage}
        expandedFilters={expandedFilters}
        setExpandedFilters={setExpandedFilters}
      />

      <div className={classes.overFlowAuto}>
        <Table
          data={contractAdjustments}
          loading={contractAdjustmentsLoading}
          columns={columns}
          meta={contractAdjustmentsMeta}
          onTableColumnSort={handleTableSort}
          tableSort={tableSort}
          page={page}
          onChangePage={handleChangePage}
        />
      </div>

      <WarningDialog
        open={confirmationDialog.visible}
        title={<Trans>Are you sure you want to sign this adjustment?</Trans>}
        onClose={() => setConfirmationDialog({visible: false, row: null})}
        onConfirm={signContractAdjustment}
        disable={contractAdjustmentLoading}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      patchMyContractAdjustments,
      getMyContractAdjustments,
      downloadMyContractAdjustments,
      downloadMyUnsignedContractAdjustments,
      getUnsignedContractAdjustments,
    },
    dispatch
  )
}

export default compose(
  withStyles((theme) => ({
    ...detailPageStyle(theme),
    ...buttonStyle(theme),
  })),
  connect((store) => {
    return {
      rowsPerPage: store.globalSettings.rowsPerPage,
      contractAdjustments: store.cfs.contractAdjustments,
      contractAdjustmentsMeta: store.cfs.contractAdjustmentsMeta,
      contractAdjustmentsLoading: store.cfs.contractAdjustmentsLoading,
    }
  }, mapDispatchToProps)
)(AdjustmentTable)
