// auth action types
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'

// cfs
export const GET_CFS_LIST_REQUEST = 'GET_CFS_LIST_REQUEST'
export const GET_CFS_LIST_SUCCESS = 'GET_CFS_LIST_SUCCESS'
export const GET_CFS_LIST_FAIL = 'GET_CFS_LIST_FAIL'
export const GET_CFS_REQUEST = 'GET_CFS_REQUEST'
export const GET_CFS_SUCCESS = 'GET_CFS_SUCCESS'
export const GET_CFS_FAIL = 'GET_CFS_FAIL'

export const GET_CARS_REQUEST = 'GET_CARS_REQUEST'
export const GET_CARS_SUCCESS = 'GET_CARS_SUCCESS'
export const GET_CARS_FAIL = 'GET_CARS_FAIL'

export const GET_ATTACHMENTS_REQUEST = 'GET_ATTACHMENTS_REQUEST'
export const GET_ATTACHMENTS_SUCCESS = 'GET_ATTACHMENTS_SUCCESS'
export const GET_ATTACHMENTS_FAIL = 'GET_ATTACHMENTS_FAIL'

export const GET_MY_SUPPLIERS_REQUEST = 'GET_MY_SUPPLIERS_REQUEST'
export const GET_MY_SUPPLIERS_SUCCESS = 'GET_MY_SUPPLIERS_SUCCESS'
export const GET_MY_SUPPLIERS_FAIL = 'GET_MY_SUPPLIERS_FAIL'

export const GET_MY_FLEETS_REQUEST = 'GET_MY_FLEETS_REQUEST'
export const GET_MY_FLEETS_SUCCESS = 'GET_MY_FLEETS_SUCCESS'
export const GET_MY_FLEETS_FAIL = 'GET_MY_FLEETS_FAIL'

export const GET_PREDEFINED_TERMS_REQUEST = 'GET_PREDEFINED_TERMS_REQUEST'
export const GET_PREDEFINED_TERMS_SUCCESS = 'GET_PREDEFINED_TERMS_SUCCESS'
export const GET_PREDEFINED_TERMS_FAIL = 'GET_PREDEFINED_TERMS_FAIL'

export const GET_FLEET_TERMS_REQUEST = 'GET_FLEET_TERMS_REQUEST'
export const GET_FLEET_TERMS_SUCCESS = 'GET_FLEET_TERMS_SUCCESS'
export const GET_FLEET_TERMS_FAIL = 'GET_FLEET_TERMS_FAIL'

export const GET_ADJUSTMENTS_REQUEST = 'GET_ADJUSTMENTS_REQUEST'
export const GET_ADJUSTMENTS_SUCCESS = 'GET_ADJUSTMENTS_SUCCESS'
export const GET_ADJUSTMENTS_FAIL = 'GET_ADJUSTMENTS_FAIL'

export const GET_SUP_AVAILA_REQUEST = 'GET_SUP_AVAILA_REQUEST'
export const GET_SUP_AVAILA_SUCCESS = 'GET_SUP_AVAILA_SUCCESS'
export const GET_SUP_AVAILA_FAIL = 'GET_SUP_AVAILA_FAIL'

export const GET_UNSIGNED_CONTRACT_REQUEST = 'GET_UNSIGNED_CONTRACT_REQUEST'
export const GET_UNSIGNED_CONTRACT_SUCCESS = 'GET_UNSIGNED_CONTRACT_SUCCESS'
export const GET_UNSIGNED_CONTRACT_FAIL = 'GET_UNSIGNED_CONTRACT_FAIL'

// auth users
export const GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST'
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS'
export const GET_CURRENT_USER_FAIL = 'GET_CURRENT_USER_FAIL'
export const PUT_CURRENT_USER_REQUEST = 'PUT_CURRENT_USER_REQUEST'
export const PUT_CURRENT_USER_SUCCESS = 'PUT_CURRENT_USER_SUCCESS'
export const PUT_CURRENT_USER_FAIL = 'PUT_CURRENT_USER_FAIL'
export const PUT_CHANGE_PASSWORD_REQUEST = 'PUT_CHANGE_PASSWORD_REQUEST'
export const PUT_CHANGE_PASSWORD_SUCCESS = 'PUT_CHANGE_PASSWORD_SUCCESS'
export const PUT_CHANGE_PASSWORD_FAIL = 'PUT_CHANGE_PASSWORD_FAIL'

export const UPDATE_GLOBAL_SETTINGS_SUCCESS = 'UPDATE_GLOBAL_SETTINGS_SUCCESS'
