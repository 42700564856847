import cx from 'classnames'
import PrimaryButton from 'component/material/PrimaryButton'
import {getKeyByValue, redirectTo} from 'helper/functions'
import PrivateLayoutTopBar from 'layout/PrivateLayout/PrivateLayoutTopBar'
import privateLayoutStyle from 'layout/PrivateLayout/privateLayoutStyle'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {getCurrentUser} from 'redux/action/authAction'
import {getUnsignedContractAdjustments} from 'redux/action/cfsAction'
import {Trans} from '@lingui/macro'
import {Badge, Tab, Tabs} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

const PrivateLayout = (props) => {
  const {
    classes,
    children,
    getCurrentUser,
    currentUser,
    currentUserLoading,
    cfs,
    cfsLoading,
    getUnsignedContractAdjustments,
    numberOfUnsignedContract,
  } = props

  const [tabValue, setTabValue] = useState(0)

  let location = useLocation()

  const isDisabled = cfs?.status !== 3

  const getTitleInvoice = () => {
    const detailId = location.pathname?.split('/')?.[2]
    if (detailId === 'new') {
      return (
        <>
          <PrimaryButton
            size={'large'}
            variant={'outlined'}
            className={cx(classes.outlinedButton, classes.marginRight)}
            fullWidth={false}
            textPadding={0}
            text={<Trans>Back</Trans>}
            onClick={() => redirectTo('/invoices')}
          />

          <PrimaryButton
            size={'large'}
            fullWidth={false}
            textPadding={0}
            text={<Trans>Save</Trans>}
            onClick={() => document.getElementById('submit-button').click()}
          />
        </>
      )
    }
    if (!isNaN(Number(detailId))) {
      return (
        !cfsLoading && (
          <>
            <PrimaryButton
              size={'large'}
              className={cx(classes.outlinedButton, classes.marginRight)}
              variant={'outlined'}
              fullWidth={false}
              textPadding={0}
              text={<Trans>Back</Trans>}
              onClick={() => redirectTo('/invoices')}
            />
            {!isDisabled && (
              <PrimaryButton
                size={'large'}
                fullWidth={false}
                textPadding={0}
                text={<Trans>Save</Trans>}
                onClick={() => document.getElementById('submit-button').click()}
              />
            )}
          </>
        )
      )
    }
  }

  const getTitle = () => {
    const detailId = location.pathname?.split('/')?.[2]
    if (detailId === 'new') return newTitleRouteMap[tabValue]
    if (!isNaN(Number(detailId))) return detailTitleRouteMap[tabValue]
    return titleRouteMap[tabValue]
  }

  const tabRouteMap = ['/invoices', '/fleets', '/contract-adjustments']

  const contractAdjustmentsIndex = 2
  const titleRouteMap = [
    <Trans>Invoices</Trans>,
    <Trans>Fleets</Trans>,
    <Trans>Contract adjustments</Trans>,
  ]
  const newTitleRouteMap = [
    <Trans>New invoice</Trans>,
    <Trans>New fleet</Trans>,
    <Trans>New contract adjustments</Trans>,
  ]
  const detailTitleRouteMap = [
    <Trans>Invoice detail</Trans>,
    <Trans>Fleet detail</Trans>,
    <Trans>Contract adjustments detail</Trans>,
  ]

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
    redirectTo(tabRouteMap[newValue])
  }

  useEffect(() => {
    getCurrentUser().catch(() => {})
    getUnsignedContractAdjustments().catch(() => {})
  }, [])

  // Open correct tab based on route
  useEffect(() => {
    let rootRoute = location.pathname?.split('/')?.[1]
    setTabValue(Number(getKeyByValue(tabRouteMap, '/' + rootRoute)))
  }, [location])

  return (
    <div className={classes.root}>
      <PrivateLayoutTopBar loading={currentUserLoading} currentUser={currentUser} />

      <main className={classes.content}>
        <div className={classes.pageTitle}>
          {getTitle()}
          <div>{getTitleInvoice()}</div>
        </div>
        <div className={classes.scrollableTabsWidth}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tabValue}
            onChange={handleChangeTab}
            className={classes.customTabs}
          >
            {titleRouteMap.map((t, i) => (
              <Tab
                key={i}
                label={
                  i === contractAdjustmentsIndex ? (
                    <Badge badgeContent={numberOfUnsignedContract} color="error">
                      {t}
                    </Badge>
                  ) : (
                    t
                  )
                }
                disableRipple={true}
              />
            ))}
          </Tabs>
        </div>

        {children}
      </main>
    </div>
  )
}

PrivateLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  getCurrentUser: PropTypes.func,
  currentUser: PropTypes.object,
  currentUserLoading: PropTypes.bool,
  cfs: PropTypes.object,
  cfsLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCurrentUser,
      getUnsignedContractAdjustments,
    },
    dispatch
  )
}

export default compose(
  withStyles(privateLayoutStyle),
  connect((store) => {
    return {
      currentUser: store.auth.currentUser,
      currentUserLoading: store.auth.currentUserLoading,
      cfs: store.cfs.cfs,
      cfsLoading: store.cfs.cfsLoading,
      numberOfUnsignedContract: store.cfs.numberOfUnsignedContract,
    }
  }, mapDispatchToProps)
)(PrivateLayout)
