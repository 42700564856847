import endpoints from 'helper/endpoints'
import {
  constructGetOrderBy,
  fireErrorToast,
  fireSuccessToast,
  globalApiErrorHandler,
} from 'helper/functions'
import httpClient from 'helper/httpClient'
import React from 'react'
import {
  GET_ADJUSTMENTS_FAIL,
  GET_ADJUSTMENTS_REQUEST,
  GET_ADJUSTMENTS_SUCCESS,
  GET_ATTACHMENTS_FAIL,
  GET_ATTACHMENTS_REQUEST,
  GET_ATTACHMENTS_SUCCESS,
  GET_CARS_FAIL,
  GET_CARS_REQUEST,
  GET_CARS_SUCCESS,
  GET_CFS_FAIL,
  GET_CFS_LIST_FAIL,
  GET_CFS_LIST_REQUEST,
  GET_CFS_LIST_SUCCESS,
  GET_CFS_REQUEST,
  GET_CFS_SUCCESS,
  GET_FLEET_TERMS_FAIL,
  GET_FLEET_TERMS_REQUEST,
  GET_FLEET_TERMS_SUCCESS,
  GET_MY_FLEETS_FAIL,
  GET_MY_FLEETS_REQUEST,
  GET_MY_FLEETS_SUCCESS,
  GET_MY_SUPPLIERS_FAIL,
  GET_MY_SUPPLIERS_REQUEST,
  GET_MY_SUPPLIERS_SUCCESS,
  GET_PREDEFINED_TERMS_FAIL,
  GET_PREDEFINED_TERMS_REQUEST,
  GET_PREDEFINED_TERMS_SUCCESS,
  GET_SUP_AVAILA_FAIL,
  GET_SUP_AVAILA_REQUEST,
  GET_SUP_AVAILA_SUCCESS,
  GET_UNSIGNED_CONTRACT_FAIL,
  GET_UNSIGNED_CONTRACT_REQUEST,
  GET_UNSIGNED_CONTRACT_SUCCESS,
} from 'redux/actionType'
import {Trans} from '@lingui/macro'

export const getCfsList = (limit = 1000, offset = 0, meta = {}, search) => {
  return (dispatch) => {
    dispatch({type: GET_CFS_LIST_REQUEST})
    const searchQuery = search ? `?search=${search}` : ''
    return httpClient
      .get(endpoints.invoices + searchQuery, {
        limit: limit,
        offset: offset,
        order_by: constructGetOrderBy(meta),
      })
      .then((res) => {
        dispatch({type: GET_CFS_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CFS_LIST_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getCfs = (id) => {
  return (dispatch) => {
    dispatch({type: GET_CFS_REQUEST})
    return httpClient
      .get(endpoints.invoices + '/' + id)
      .then((res) => {
        dispatch({type: GET_CFS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CFS_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const createCfs = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.invoices, data)
      .then((res) => {
        fireSuccessToast(<Trans>New invoice created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const patchCfs = (id, data) => {
  return (dispatch) => {
    return httpClient
      .patch(endpoints.invoices + '/' + id, data)
      .then((res) => {
        fireSuccessToast(<Trans>Invoice updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const getCar = (filters = null) => {
  return (dispatch) => {
    dispatch({type: GET_CARS_REQUEST})
    return httpClient
      .get(endpoints.cars, {
        rz: filters.rz ? filters.rz : null,
        vin: filters.vin ? filters.vin : null,
      })
      .then((res) => {
        dispatch({type: GET_CARS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CARS_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getAttachments = () => {
  return (dispatch) => {
    dispatch({type: GET_ATTACHMENTS_REQUEST})
    return httpClient
      .get(endpoints.attachments, {limit: 1000, offset: 0})
      .then((res) => {
        dispatch({type: GET_ATTACHMENTS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_ATTACHMENTS_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getMySuppliers = () => {
  return (dispatch) => {
    dispatch({type: GET_MY_SUPPLIERS_REQUEST})
    return httpClient
      .get(endpoints.mySupplier)
      .then((res) => {
        dispatch({type: GET_MY_SUPPLIERS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_MY_SUPPLIERS_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getMyFleets = (
  limit = 1000,
  offset = 0,
  meta = {orderBy: 'customer', orderDirection: 'ASC'}
) => {
  return (dispatch) => {
    dispatch({type: GET_MY_FLEETS_REQUEST})
    return httpClient
      .get(endpoints.myFleets, {limit, offset, order_by: constructGetOrderBy(meta)})
      .then((res) => {
        dispatch({type: GET_MY_FLEETS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_MY_FLEETS_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getMyFleetTerms = (fleetId, meta) => {
  return (dispatch) => {
    dispatch({type: GET_FLEET_TERMS_REQUEST})
    return httpClient
      .get(`${endpoints.myFleets}/${fleetId}/terms`, {order_by: constructGetOrderBy(meta)})
      .then((res) => {
        dispatch({type: GET_FLEET_TERMS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_FLEET_TERMS_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getPredefinedTerms = (fleetId) => {
  return (dispatch) => {
    dispatch({type: GET_PREDEFINED_TERMS_REQUEST})
    return httpClient
      .get(`${endpoints.fleets}/${fleetId}/predefined-terms`)
      .then((res) => {
        dispatch({type: GET_PREDEFINED_TERMS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_PREDEFINED_TERMS_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getSupUnavailabilities = () => {
  return (dispatch) => {
    dispatch({type: GET_SUP_AVAILA_REQUEST})
    return httpClient
      .get(endpoints.mySupplier + '/unavailabilities', {limit: 1000, offset: 0})
      .then((res) => {
        dispatch({type: GET_SUP_AVAILA_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_SUP_AVAILA_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const putSupUnavailabilities = (data) => {
  return (dispatch) => {
    return httpClient
      .put(endpoints.mySupplier + '/unavailabilities', data)
      .then((res) => {
        fireSuccessToast(<Trans>Unavailability dates updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export const getMyContractAdjustments = (limit = 1000, offset = 0, meta = {}, filter = {}) => {
  return (dispatch) => {
    dispatch({type: GET_ADJUSTMENTS_REQUEST})
    return httpClient
      .get(endpoints.myContractAdjustments, {
        limit: limit,
        offset: offset,
        order_by: constructGetOrderBy(meta),
        ...filter,
      })
      .then((res) => {
        dispatch({type: GET_ADJUSTMENTS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_ADJUSTMENTS_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const getUnsignedContractAdjustments = (
  limit = 100,
  offset = 0,
  filter = {is_signed: 0}
) => {
  return (dispatch) => {
    dispatch({type: GET_UNSIGNED_CONTRACT_REQUEST})
    return httpClient
      .get(endpoints.myContractAdjustments, {limit, offset, ...filter})
      .then((res) => {
        dispatch({type: GET_UNSIGNED_CONTRACT_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_UNSIGNED_CONTRACT_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const downloadMyContractAdjustments = (adjustmentId) => {
  return (dispatch) => {
    return httpClient
      .get(
        `${endpoints.myContractAdjustments}/${adjustmentId}/download-signed-file`,
        {},
        {
          headers: {
            'Content-Type': 'application/pdf',
          },
          responseType: 'arraybuffer',
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export const downloadMyUnsignedContractAdjustments = (adjustmentId) => {
  return (dispatch) => {
    return httpClient
      .get(
        `${endpoints.myContractAdjustments}/${adjustmentId}/download-unsigned-file`,
        {},
        {
          headers: {
            'Content-Type': 'application/pdf',
          },
          responseType: 'arraybuffer',
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export const patchMyContractAdjustments = (adjustmentId, data) => {
  return (dispatch) => {
    return httpClient
      .patch(`${endpoints.myContractAdjustments}/${adjustmentId}`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export const downloadMyContract = () => {
  return (dispatch) => {
    return httpClient
      .get(
        `${endpoints.myContract}/download`,
        {},
        {
          headers: {
            'Content-Type': 'application/pdf',
          },
          responseType: 'arraybuffer',
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export const downloadMyContractArchive = () => {
  return (dispatch) => {
    return httpClient
      .get(
        `${endpoints.myContract}/download-archive`,
        {},
        {
          headers: {
            'Content-Type': 'application/zip',
          },
          responseType: 'arraybuffer',
        }
      )
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}
