const privateLayoutStyle = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.elitDarkGray,
  },

  // TOP BAR WITH LOGOS
  topBar: {
    height: 66,
    padding: '0 30px',
    boxShadow: '0 2px 14px 0 rgba(0, 0, 0, 0.08)',
    border: 'solid 1px ' + theme.palette.border,
    backgroundColor: theme.palette.background.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  topBarLKQ: {
    height: 40,
    width: 'auto',
    paddingRight: 30,
    display: 'flex',
    alignItems: 'center',
  },
  topBarElit: {
    '& img': {
      height: 40,
      width: 'auto',
      display: 'flex',
      alignItems: 'center',
    },
  },
  userMenu: {
    fontSize: 13,
  },
  nameLoader: {
    width: 8,
    height: 8,
    padding: 4,
    border: '2px solid transparent',
    borderTop: '2px solid #004b8090',
  },
  userName: {
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
  },
  svgDots: {
    height: '100%',
    padding: '0 10px 0 10px',
    marginLeft: '10px',
    display: 'flex',
    cursor: 'pointer',

    '&:hover': {
      '& svg': {
        fill: theme.palette.text.primary,
      },
    },
  },
  calendarTopBar: {
    display: 'flex',
    marginRight: 12,
    cursor: 'pointer',
    '& svg': {
      fill: 'rgb(186, 189, 204)',
    },
    '&:hover': {
      '& svg': {
        fill: theme.palette.text.primary,
      },
    },
  },
  content: {
    flexGrow: 1,
    padding: '20px 40px 40px 40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: theme.palette.background.elitDarkGray,
  },
  pageTitle: {
    lineHeight: 1,
    fontSize: 26,
    fontWeight: 700,
    paddingBottom: 20,
    minHeight: 50,
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  customTabs: {
    borderRadius: theme.shape.borderRadius,
    border: '1px solid ' + theme.palette.border,
    backgroundColor: theme.palette.background.default,

    '& .MuiTab-root': {
      minWidth: '50px',
      padding: '4px 18px',
      fontSize: 13,
      borderRight: '1px solid ' + theme.palette.border,
      '&:last-child': {
        borderRight: 'none',
      },

      '&:hover': {
        opacity: 1,
      },
    },

    '& .MuiTab-wrapper': {
      textTransform: 'none',
    },

    '& .MuiTab-textColorInherit': {
      borderBottom: '0px solid ' + theme.palette.border,
    },

    '& .MuiTab-textColorInherit.Mui-selected': {
      transition: 'background-color 200ms',
      backgroundColor: theme.palette.white,
      borderBottom: '0px solid transparent',
    },

    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.white,
      height: 0,
    },

    '& .MuiTabScrollButton-root': {
      borderBottom: '0px solid ' + theme.palette.border,
    },

    '& .MuiTabScrollButton-root.Mui-disabled': {
      opacity: 0,
      width: 0,
      transition: 'width 200ms',
    },
  },

  scrollableTabsWidth: {
    width: '100%',
    paddingBottom: 20,
  },

  dropDownMenu: {
    '& .MuiList-padding': {
      padding: 0,
      borderRadius: theme.shape.borderRadius,
      border: '1px solid ' + theme.palette.border,
    },
  },

  dropDownMenuItem: {
    color: theme.palette.text.primary,
    fontSize: 14,
    '&:hover': {
      backgroundColor: theme.palette.border + 'aa',
    },
    '& svg': {
      fill: theme.palette.text.primary,
      height: 16,
      width: 16,
      paddingRight: 10,
    },
  },

  outlinedButton: {
    backgroundColor: 'white !important',
  },

  languageSelectContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },

  languageSelect: {
    backgroundColor: 'white !important',
    '&.MuiInput-underline:before': {
      borderBottom: '0px solid transparent !important',
    },
    '&.MuiInput-underline:after': {
      borderBottom: '0px solid transparent !important',
    },
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        width: 20,
      },
      padding: '0px 12px',
      margin: '0px 12px',
      '&:focus': {
        backgroundColor: 'white !important',
      },
    },
    '& .MuiSelect-icon': {
      top: '-2px !important',
    },
  },

  languageSelectItem: {
    '& .MuiMenu-list': {
      '& .MuiMenuItem-root': {
        padding: '6px 10px',
      },
    },
    '& svg': {
      width: 20,
    },
  },

  marginRight: {
    marginRight: 12,
  },
})

export default privateLayoutStyle
