import cx from 'classnames'
import detailPageStyle from 'component/detailPageStyle'
import BoxFullWidth from 'component/material/BoxFullWidth'
import DialogWindow from 'component/material/DialogWindow'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import Loader from 'component/material/Loader'
import PrimaryButton from 'component/material/PrimaryButton'
import Table from 'component/material/table/Table'
import download from 'downloadjs'
import {DATE_FORMAT} from 'helper/constants'
import {getContentDispositionFilename} from 'helper/functions'
import moment from 'moment/moment'
import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {
  downloadMyContractAdjustments,
  downloadMyUnsignedContractAdjustments,
  getMyContractAdjustments,
  getMyFleetTerms,
  getUnsignedContractAdjustments,
  patchMyContractAdjustments,
} from 'redux/action/cfsAction'
import buttonStyle from 'style/components/buttonStyle'
import {Trans} from '@lingui/macro'
import {Link} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

const FleetTermsDialog = (props) => {
  const {
    classes,
    open,
    onClose,
    row,
    fleetTermsLoading,
    fleetTerms,
    contractAdjustments,
    contractAdjustmentsLoading,
    downloadMyContractAdjustments,
    getMyFleetTerms,
    getMyContractAdjustments,
    getUnsignedContractAdjustments,
    patchMyContractAdjustments,
    handleGetMyFleets,
    downloadMyUnsignedContractAdjustments,
  } = props

  const handleDownloadAdjustment = (adjustmentId) => {
    downloadMyContractAdjustments(adjustmentId)
      .then((res) => {
        const filename = getContentDispositionFilename(res.headers)
        const data = res.data
        download(data, filename, 'application/pdf')

        // create blob from array buffer in order to create url and then open it in new tab
        const blob = new Blob([data], {type: 'application/pdf'})
        const url = URL.createObjectURL(blob)
        window.open(url, '_blank')
      })
      .catch(() => {})
  }

  const handleDownloadUnsignedAdjustment = (adjustmentId) => {
    downloadMyUnsignedContractAdjustments(adjustmentId)
      .then((res) => {
        const filename = getContentDispositionFilename(res.headers)
        const data = res.data
        download(data, filename, 'application/pdf')

        // create blob from array buffer in order to create url and then open it in new tab
        const blob = new Blob([data], {type: 'application/pdf'})
        const url = URL.createObjectURL(blob)
        window.open(url, '_blank')
      })
      .catch(() => {})
  }

  const adjustmentColumns = [
    {
      name: 'signed_file',
      label: <Trans>Name</Trans>,
      render: (val, row) =>
        val?.filename ? (
          <Link
            className={classes.cursorPointer}
            onClick={() => row.id && handleDownloadAdjustment(row.id)}
          >
            {val.filename}
          </Link>
        ) : (
          <Link
            className={classes.cursorPointer}
            onClick={() => row.id && handleDownloadUnsignedAdjustment(row.id)}
          >
            {row.unsigned_file?.filename || <Trans>Download contract adjustment preview</Trans>}
          </Link>
        ),
    },
    {
      name: 'signed_at',
      label: <Trans>Signed at</Trans>,
      render: (val) => <span>{val && moment(val).format(DATE_FORMAT)}</span>,
    },
    {
      name: 'created_at',
      label: <Trans>Created at</Trans>,
      render: (val) => <span>{val && moment(val).format(DATE_FORMAT)}</span>,
    },
    {
      name: 'id',
      label: '',
      render: (val, row) =>
        !row.signed_at && (
          <PrimaryButton
            size={'small'}
            fullWidth={false}
            textPadding={0}
            text={<Trans>Confirm</Trans>}
            onClick={() => signContractAdjustment(row)}
          />
        ),
    },
  ]

  const signContractAdjustment = (row) => {
    patchMyContractAdjustments(row.id, {sign: true})
      .then(() => {
        handleGetMyFleets()
        onClose()
        getUnsignedContractAdjustments().catch(() => {})
      })
      .catch(() => {})
  }

  useEffect(() => {
    if (open && row.id) {
      getMyFleetTerms(row.id, {}).catch(() => {})
      getMyContractAdjustments(null, null, null, {fleet_id: row.id}).catch(() => {})
    }
  }, [open, row])

  return (
    <DialogWindow
      title={<Trans>Terms of cooperation</Trans>}
      open={open}
      onClose={onClose}
      maxWidth={'md'}
      fullWidth={true}
    >
      {fleetTermsLoading && <Loader />}
      {fleetTerms?.map((t, i) => (
        <div key={t.id}>
          {i === 0 && (
            <GridContainer style={{borderBottom: '1px solid black', marginBottom: '4px'}}>
              <GridItem xs={6}>
                <Typography variant={'body1'} style={{fontWeight: 600}}>
                  <Trans>Type of work</Trans>
                </Typography>
              </GridItem>
              <GridItem xs={2}>
                <Typography variant={'body1'} style={{fontWeight: 600}}>
                  <Trans>Price per unit</Trans>
                </Typography>
              </GridItem>
              <GridItem xs={2}>
                <Typography variant={'body1'} style={{fontWeight: 600}}>
                  <Trans>Insured event</Trans>
                </Typography>
              </GridItem>
              <GridItem xs={2}>
                <Typography variant={'body1'} style={{fontWeight: 600}}>
                  <Trans>Region</Trans>
                </Typography>
              </GridItem>
            </GridContainer>
          )}
          <GridContainer spacing={2} className={i % 2 ? classes.lightBackgroundColor : null}>
            <GridItem xs={6}>
              <Typography variant={'body2'}>{t.type_of_work}</Typography>
            </GridItem>
            <GridItem xs={2}>
              <Typography variant={'body2'}>{`${Math.round(t.price * 100) / 100} ${
                t.unit
              }`}</Typography>
            </GridItem>
            <GridItem xs={2}>
              <Typography variant={'body2'}>
                {t.insured_event ? <Trans>Yes</Trans> : <Trans>No</Trans>}
              </Typography>
            </GridItem>
            <GridItem xs={2}>
              <Typography variant={'body2'}>{t.pretty_region ? t.pretty_region : '---'}</Typography>
            </GridItem>
          </GridContainer>
        </div>
      ))}

      <GridContainer>
        <BoxFullWidth pt={6}>
          <Typography variant={'h6'} color={'primary'} align={'center'}>
            <Trans>History of contract adjustments</Trans>
          </Typography>
        </BoxFullWidth>
        <Table
          data={contractAdjustments}
          columns={adjustmentColumns}
          loading={contractAdjustmentsLoading}
          pagination={false}
          className={cx(classes.lastRowRoundBorder, classes.detailTable)}
        />
      </GridContainer>
    </DialogWindow>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMyFleetTerms,
      getMyContractAdjustments,
      downloadMyContractAdjustments,
      getUnsignedContractAdjustments,
      patchMyContractAdjustments,
      downloadMyUnsignedContractAdjustments,
    },
    dispatch
  )
}

export default compose(
  withStyles((theme) => ({
    ...buttonStyle(theme),
    ...detailPageStyle(theme),
  })),
  connect((store) => {
    return {
      fleetTermsLoading: store.cfs.fleetTermsLoading,
      fleetTerms: store.cfs.fleetTerms,
      contractAdjustmentsLoading: store.cfs.contractAdjustmentsLoading,
      contractAdjustments: store.cfs.contractAdjustments,
    }
  }, mapDispatchToProps)
)(FleetTermsDialog)
