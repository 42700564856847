import cx from 'classnames'
import CreatePasswordForm from 'component/LoginPage/CreatePasswordForm'
import ResetPasswordForm from 'component/LoginPage/ResetPasswordForm'
import PasswordInput from 'component/field/PasswordInput'
import TextInput from 'component/field/TextInput'
import fieldStyle from 'component/field/fieldStyle'
import BoxFullWidth from 'component/material/BoxFullWidth'
import DialogWindow from 'component/material/DialogWindow'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {mapErrorResponseToForm} from 'helper/functions'
import {required} from 'helper/validations'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {login} from 'redux/action/authAction'
import {ReactComponent as Person} from 'style/asset/old/person.svg'
import {Trans} from '@lingui/macro'
import InputAdornment from '@material-ui/core/InputAdornment'
import withStyles from '@material-ui/core/styles/withStyles'

const LoginForm = (props) => {
  const {classes, login, loginLoading} = props

  const [openResetPassword, setOpenResetPassword] = useState(false)
  const [openCreateNewPassword, setOpenCreateNewPassword] = useState(false)
  const [resetPasswordToken, setResetPasswordToken] = useState('')

  const handleSubmit = (values) => {
    return login(values).catch((err) => mapErrorResponseToForm(err))
  }

  // control forgotten password dialog
  const handleOpenResetPassword = () => {
    setOpenResetPassword(true)
  }
  const handleCloseResetPassword = () => {
    setOpenResetPassword(false)
  }

  // control create new password dialog
  const handleOpenCreateNewPassword = () => {
    setOpenCreateNewPassword(true)
  }
  const handleCloseCreateNewPassword = () => {
    window.history.pushState({}, '', '/')
    setOpenCreateNewPassword(false)
  }

  useEffect(() => {
    if (window.location.pathname.includes('/auth/reset-password/')) {
      setResetPasswordToken(window.location.pathname.split('/').slice(-1)[0])
      handleOpenCreateNewPassword()
    }
  }, [])

  return (
    <>
      {/* RESET PASSWORD DIALOG */}
      <DialogWindow
        title={<Trans>Create password</Trans>}
        maxWidth={'sm'}
        open={openCreateNewPassword}
        onClose={handleCloseCreateNewPassword}
      >
        <CreatePasswordForm
          resetPasswordToken={resetPasswordToken}
          handleCloseCreateNewPassword={handleCloseCreateNewPassword}
        />
      </DialogWindow>

      {/* Forgotten PASSWORD DIALOG */}
      <DialogWindow
        title={<Trans>Forgotten password</Trans>}
        maxWidth={'sm'}
        open={openResetPassword}
        onClose={handleCloseResetPassword}
      >
        <ResetPasswordForm handleCloseResetPassword={handleCloseResetPassword} />
      </DialogWindow>

      <Form onSubmit={handleSubmit}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <Field
                name="username"
                label={<Trans>Username</Trans>}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={'start'}>
                      <div
                        className={cx(
                          classes.adornmentIcon,
                          classes.grayBackground,
                          classes.loginPageAdornment
                        )}
                      >
                        <Person />
                      </div>
                    </InputAdornment>
                  ),
                }}
                component={TextInput}
                validate={required}
                autoComplete="email"
                className={cx(classes.noBorderRadiusLeft, classes.loginPageInput)}
              />
              <Field
                name="password"
                label={<Trans>Password</Trans>}
                component={PasswordInput}
                loginPage={true}
                validate={required}
                autoComplete="current-password"
                className={cx(classes.noBorderRadius, classes.loginPageInput)}
              />
              <BoxFullWidth pb={8}>
                <GridContainer justifyContent={'flex-end'} className={classes.loginText}>
                  <GridItem onClick={handleOpenResetPassword}>
                    <Trans>Forgotten password</Trans>
                  </GridItem>
                </GridContainer>
              </BoxFullWidth>
              <BoxFullWidth>
                <PrimaryButton
                  className={classes.loginButton}
                  loading={loginLoading}
                  type="submit"
                  text={<Trans>Login</Trans>}
                />
              </BoxFullWidth>
            </form>
          )
        }}
      </Form>
    </>
  )
}

LoginForm.propTypes = {
  classes: PropTypes.object,
  login: PropTypes.func.isRequired,
  loginLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      login,
    },
    dispatch
  )
}

export default compose(
  withStyles(fieldStyle),
  connect((store) => {
    return {
      loginLoading: store.auth.loginLoading,
    }
  }, mapDispatchToProps)
)(LoginForm)
